import { calculateMaxAgeFromExpiresAt, checkCookieIsSet, setCookie } from '@3mundi/smartscript-core';
import type { AuthState, OktaAuth } from '@okta/okta-auth-js';
import { useState, useEffect } from 'react';

export const ACCESS_TOKEN = 'protas_access_token';

export const doSetOktaAccessTokenCookie = (authState: AuthState, oktaAuth: OktaAuth): void => {
	const token = oktaAuth.getAccessToken();

	if (!token) {
		return undefined;
	}

	if (authState && authState.accessToken) {
		const maxAge = calculateMaxAgeFromExpiresAt(authState.accessToken.expiresAt);

		return setCookie({ cookieName: ACCESS_TOKEN, token, maxAge });
	}
};

const FIVE_HUNDRED_MILLISECONDS = 500;

export const useCookieListener = (): boolean => {
	const [cookieIsSet, setCookieIsSet] = useState(() => checkCookieIsSet(ACCESS_TOKEN));

	useEffect(() => {
		const nextState = !cookieIsSet;

		const intervalID = setInterval(() => {
			if (checkCookieIsSet(ACCESS_TOKEN) === nextState) {
				setCookieIsSet(nextState);
			}
		}, FIVE_HUNDRED_MILLISECONDS);

		return () => {
			clearInterval(intervalID);
		};
	}, [cookieIsSet]);

	return cookieIsSet;
};
