import type { AlertProps } from '@mui/material';
import { Alert, Typography } from '@mui/material';
import type { FC } from 'react';

export const pleaseOpenInAmadeusMessage = 'Login successful. Please open the SmartScript in Amadeus';

type H3AlertProps = AlertProps & { children: React.ReactNode};

export const H3Alert: FC<H3AlertProps> = ({ children, ...alertProps }): JSX.Element => {
	return (
		<Alert {...alertProps}>
			<Typography variant='h3'>
				{children}
			</Typography>
		</Alert>
	);
};
