import { createRoot } from 'react-dom/client';
import App from './App';

const baseConfig = {
	pkce: true,
	disableHttpsCheck: false,
	scopes: ['openid', import.meta.env.VITE_PS_COMPANY_API_SCOPE],
};
const config = {
	...baseConfig,
	clientId: import.meta.env.VITE_CLIENT_ID,
	issuer: import.meta.env.VITE_ISSUER,
	redirectUri: import.meta.env.VITE_REDIRECT_URI,
};

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
	<App config={config} />
);
