import {
	coreTheme,
} from '@3mundi/smartscript-core';
import { createTheme } from '@mui/material/styles';
import { deepmerge } from '@mui/utils';
import { primaryBlue } from './constants';

const FONT_WEIGHT_LIGHT = 500;
const FONT_WEIGHT_REGULAR = 600;
const FONT_WEIGHT_MEDIUM = 700;
const FONT_WEIGHT_BOLD = 800;

export const OktaSpaTheme = {
	typography: {
		fontFamily: 'Open Sans',
		fontWeightLight: FONT_WEIGHT_LIGHT,
		fontWeightRegular: FONT_WEIGHT_REGULAR,
		fontWeightMedium: FONT_WEIGHT_MEDIUM,
		fontWeightBold: FONT_WEIGHT_BOLD,
	},
	MuiGrid: {
		styleOverrides: {
			root: {
				'&.header-icon.valid svg': {
					color: primaryBlue,
				},
			},
		},
	},
	MuiSvgIcon: {
		styleOverrides: {
			root: {
				color: primaryBlue,
			},
		},
	},
	MuiCircularProgress: {
		styleOverrides: {
			root: {
				'&.busy-indicator': {
					color: primaryBlue,
				},
			},
		},
	},
	palette: {
		secondary: {
			main: primaryBlue,
		},
	},
};
export const theme = createTheme(deepmerge(coreTheme, OktaSpaTheme));
