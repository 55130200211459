import type { OktaAuthOptions } from '@okta/okta-auth-js';
import type { FC } from 'react';
import { OktaAuth } from '@okta/okta-auth-js';
import { LoginCallback, Security } from '@okta/okta-react';
import Home from './pages/Home';
import { ThemeProvider } from '@emotion/react';
import { theme } from './Styles/Theme';
import { doNothing, RollbarProvider } from '@3mundi/smartscript-core';

type AppProps = {
	config: OktaAuthOptions
}

const App: FC<AppProps> = ({ config }) => {
	const oktaAuth = new OktaAuth(config);

	return (
		<RollbarProvider
			accessToken={import.meta.env.VITE_ROLLBAR_ACCESS_TOKEN ?? ''}
			codeVersion={import.meta.env.VITE_COMMIT_SHORT_SHA ?? ''}
			config={{ enabled: import.meta.env.PROD }}
		>
			<Security oktaAuth={oktaAuth} restoreOriginalUri={doNothing}>
				<ThemeProvider theme={theme}>
					<Home/>
					<LoginCallback/>
				</ThemeProvider>
			</Security>
		</RollbarProvider>
	);
};

export default App;
